<template><div class="advert">
	<div class="box"><div class="p16">
		<div class="pb16"><el-button type="primary" class="ssbtn" style="width: 86px;" @click="tjDialog = true">添加</el-button></div>
		<div class="table">
			<el-table :data="tableData" border style="width: 100%;">
				<el-table-column prop="id" label="ID" width="50">
					<template slot-scope="scope">            
						<div>{{scope.row.id}}</div>
					 </template>    
				</el-table-column>
				<el-table-column prop="title" label="标题" width="230">
					<template slot-scope="scope">            
						<div>{{scope.row.title}}</div>
					 </template>    
				</el-table-column>
				<el-table-column prop="ad_type,pc_img,wap_img" label="封面" width="80">
					<template slot-scope="scope">
						<div v-if="scope.row.ad_type==0"><img :src="scope.row.wap_img" style="width:40px; width: 40px;vertical-align: top;" /></div>
						<div v-else-if="scope.row.ad_type==1"><img :src="scope.row.pc_img" style="width:40px; width: 40px;vertical-align: top;" /></div>
					 </template>    
				</el-table-column>
				<el-table-column prop="url" label="网址" width="230">
					<template slot-scope="scope">            
						<div>{{scope.row.url}}</div>
					 </template>    
				</el-table-column>
				<el-table-column prop="description" label="描述" width="350">
					<template slot-scope="scope">            
						<div>{{scope.row.description}}</div>
					 </template>    
				</el-table-column>
				<el-table-column prop="ad_type" label="类型" width="110">
					<template slot-scope="scope">            
						<div v-if="scope.row.ad_type==0">买家手机端</div>
						<div v-else-if="scope.row.ad_type==1">商家电脑端</div>
					 </template>    
				</el-table-column>
				<el-table-column prop="ad_type,ad_location" label="位置" width="100">
					<template slot-scope="scope">
						<div v-if="scope.row.ad_type==0">
							<div v-if="scope.row.ad_location==0">首页幻灯片</div>
							<div v-else-if="scope.row.ad_location==1">首页滚屏</div>
							<div v-else-if="scope.row.ad_location==2">个人中心图片</div>
						</div>						
					 </template>    
				</el-table-column>
				<el-table-column prop="created_at" label="创建时间" width="160">
					<template slot-scope="scope">            
						<div>{{scope.row.created_at}}</div>
					 </template>    
				</el-table-column>
				<el-table-column prop="sort" label="排序" width="70">
					<template slot-scope="scope">            
						<div v-if="scope.row.sort==null">0</div>
						<div v-else>{{scope.row.sort}}</div>
					 </template>    
				</el-table-column>
				<el-table-column prop="status" label="状态" width="80">
					<template slot-scope="scope">            
						<div v-if="scope.row.status==0">正常</div>
						<div v-else-if="scope.row.status==1">下架</div>
					 </template>    
				</el-table-column>
				<el-table-column label="操作" width="220" fixed="right">
					<template slot-scope="scope"><div class="flex jc_s ai_c">
					<el-button class="qxbtn mr10" @click="ckbj(scope.$index,scope.row)">编辑</el-button>
					  <div v-if="scope.row.status==0">
						  <el-button class="delbtn" style="background: #F68B44!important; margin:0 10px;" @click="ckxj(scope.$index,scope.row)">下架</el-button>
					  </div>
					<div v-else>
							<el-button class="delbtn" style="background: #F68B44!important;  margin:0 10px;" @click="cksj(scope.$index,scope.row)">上架</el-button>
					</div>
					<el-button class="delbtn" @click="ckdel(scope.$index,scope.row)">删除</el-button>
					</div></template> 
				</el-table-column>
			</el-table>
		</div><!--table end-->
		<div class="page pt16">
			<el-pagination
				 background
				 @size-change="handleSizeChange"
				  @current-change="handleCurrentChange"
				  :current-page="currentPage"
				  :page-sizes="[2, 5, 8, 10]"
				  :page-size="pageSize"
				  layout=" prev, pager, next"
				   :total="total">	
			</el-pagination>
		</div>
		
	</div></div><!--box end-->
	
	<el-dialog id="bianji" width="530px" :visible.sync="bjDialog">
		<div class="qxDialogHeader flex-column jc_c"><div class="flex jc_b ai_c">
			<span class="f16 colfff">编辑</span>
			<i class="iconfont iconguanbi colfff f18" @click="bjDialog = false"></i>
			
		</div></div>
		<div class="ggbjmid"><el-form :model="bjForm" ref="bjForm" :rules="rules">
			<div class="qxmidm pb40">
				<span class="qxmidmtxt"><b>*</b>标题</span>
				<el-form-item prop="title">
					<el-input placeholder="0" v-model="bjForm.title" auto-complete="off"></el-input>
				</el-form-item>
			</div>
			<div class="qxmidm pb30">
				<span class="qxmidmtxt">封面</span>
				<el-form-item prop="img">
					
					<el-upload name="file"
					  class="uploadFm"
					  action="11"
					  :show-file-list="false"
					  :http-request="SellerUploadImg"
					  :on-success="handleAvatarSuccess">
					  
					  <img v-if="bjForm.img" :src="bjForm.img" class="avatar">
					  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
					  
					</el-upload>
					
					
				</el-form-item>
			</div>
			<div class="qxmidm qxmidm2 pb40">
				<span class="qxmidmtxt">封面地址</span>
				<el-form-item prop="img">
					<el-input placeholder="" v-model="bjForm.img"  auto-complete="off"></el-input>
				</el-form-item>
			</div>
			<div class="qxmidm pb20">
				<span class="qxmidmtxt">网址</span>
				<el-form-item prop="url">
					<el-input placeholder="https://" v-model="bjForm.url"  auto-complete="off"></el-input>
				</el-form-item>
			</div>
			<div class="qxmidm mb20">
				<span class="qxmidmtxt">类型</span>
				<el-form-item prop="ad_type">
					<el-radio-group v-model="bjForm.ad_type" class="xzradio">
					    <el-radio :label="0">买家手机端</el-radio>
					    <el-radio :label="1">商家电脑端</el-radio>
					  </el-radio-group>
				</el-form-item>
			</div>
			<div class="qxmidm mb20">
				<span class="qxmidmtxt">位置</span>
				<el-form-item prop="ad_location">
					<el-radio-group v-model="bjForm.ad_location" class="xzradio2 xzradio2b flex jc_b ai_c">
					    <el-radio :label="0">幻灯片</el-radio>
					    <el-radio :label="1">首页滚屏</el-radio>
						<el-radio :label="2">个人中心图片</el-radio>
					  </el-radio-group>
				</el-form-item>
			</div>
			<div class="qxmidm mb20">
				<span class="qxmidmtxt">跳转类型</span>
				<el-form-item>
					<el-radio-group v-model="bjForm.jump_type" class="xzradio">
					    <el-radio :label="0">电影</el-radio>
					    <el-radio :label="1">卡券</el-radio>
					  </el-radio-group>
				</el-form-item>
			</div>
			<div v-if="bjForm.jump_type == 0" class="qxmidm pb30 mt20">
				<span class="qxmidmtxt">所属电影</span>
				<el-form-item prop="mid">
					<el-select v-model="bjForm.mid" class="xzselect" style="width: 100%;" placeholder="请选择">
						<el-option v-for="item in movieOptions" :key="item.mid" :label="item.nm" :value="item.mid"></el-option>
					</el-select>
				</el-form-item>
			</div>
			<div class="qxmidm pb30">
				<span class="qxmidmtxt">排序</span>
				<el-form-item prop="sort">
					<el-input-number v-model="bjForm.sort"  style="width: 200px!important;"  placeholder="" controls-position="right"   class="xznum" /></el-input-number>
				</el-form-item>
			</div>
			
			<div class="qxmidm pb32">
				<el-button type="primary" class="ssbtn" style="width: 5.375rem; margin-right: 80px; margin-left: 25px;"  @click="submitbjForm('bjForm')">确认</el-button>
				<el-button class="cancelbtn" style="width: 5.375rem;" @click="resetbjForm('bjForm')">取消</el-button>
			</div>
		</el-form></div><!--ggbjmid end-->
	</el-dialog>
	<el-dialog id="tianjia" class="dialogHeaderno" width="530px" :visible.sync="tjDialog">
		<div class="qxDialogHeader flex-column jc_c"><div class="flex jc_b ai_c">
			<span class="f16 colfff">添加</span>
			<i class="iconfont iconguanbi colfff f18" @click="tjDialog = false"></i>
			
		</div></div>
		<div class="ggbjmid"><el-form :model="tjForm" ref="tjForm" :rules="tjrules">
			<div class="qxmidm pb40">
				<span class="qxmidmtxt"><b>*</b>标题</span>
				<el-form-item prop="title">
					<el-input placeholder="输入标题"  v-model="tjForm.title" auto-complete="off"></el-input>
				</el-form-item>
			</div>
			<!--<div class="qxmidm pb40">
				<span class="qxmidmtxt">ID</span>
				<el-form-item prop="id">
					<el-input placeholder="输入ID" type="number"  v-model="tjForm.id" auto-complete="off"></el-input>
				</el-form-item>
			</div>-->
			<div class="qxmidm pb30">
				<span class="qxmidmtxt">封面</span>
				<el-form-item>
					<el-upload name="file"
					  class="uploadFm"
					  action="11"
					  :show-file-list="false"
					  :http-request="SellerUploadImgtj"
					  :on-success="handleAvatarSuccess">
					  
					  <img v-if="tjForm.img" :src="tjForm.img" class="avatar">
					  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
					  
					</el-upload>
				</el-form-item>
			</div>
			<div class="qxmidm qxmidm2 pb40">
				<span class="qxmidmtxt">封面地址</span>
				<el-form-item prop="img">
					<el-input placeholder="暂无" v-model="tjForm.img"  auto-complete="off"></el-input>
				</el-form-item>
			</div>
			<div class="qxmidm pb20">
				<span class="qxmidmtxt">网址</span>
				<el-form-item prop="url">
					<el-input  placeholder="https://" type="url" v-model="tjForm.url"  auto-complete="off"></el-input>
				</el-form-item>
			</div>
			<div class="qxmidm mb20">
				<span class="qxmidmtxt">类型</span>
				<el-form-item>
					<el-radio-group v-model="tjForm.ad_type" class="xzradio">
					    <el-radio :label="0">买家手机端</el-radio>
					    <el-radio :label="1">商家电脑端</el-radio>
					  </el-radio-group>
				</el-form-item>
			</div>
			<div class="qxmidm mb20">
				<span class="qxmidmtxt">位置</span>
				<el-form-item prop="ad_location">
					<el-radio-group v-model="tjForm.ad_location" class="xzradio flex jc_b ai_c">
					    <el-radio :label="0">幻灯片</el-radio>
					    <el-radio :label="1">首页滚屏</el-radio>
						<el-radio :label="2">个人中心图片</el-radio>
					  </el-radio-group>
				</el-form-item>
			</div>
			<div class="qxmidm mb20">
				<span class="qxmidmtxt">跳转类型</span>
				<el-form-item>
					<el-radio-group v-model="tjForm.jump_type" class="xzradio">
					    <el-radio :label="0">电影</el-radio>
					    <el-radio :label="1">卡券</el-radio>
					  </el-radio-group>
				</el-form-item>
			</div>
			<div  v-if="tjForm.jump_type == 0" class="qxmidm pb30 mt20">
				<span class="qxmidmtxt">所属电影</span>
				<el-form-item prop="mid">
					<el-select v-model="tjForm.mid" class="xzselect" style="width: 100%;" placeholder="请选择">
						<el-option v-for="item in movieOptions" :key="item.mid" :label="item.nm" :value="item.mid"></el-option>
					</el-select>
				</el-form-item>
			</div>
			<div class="qxmidm pb30">
				<span class="qxmidmtxt">排序</span>
				<el-form-item prop="sort">
					<el-input-number   style="width: 200px!important;"  placeholder="数值越大排序越靠前" v-model="tjForm.sort" controls-position="right"   class="xznum" /></el-input-number>
				</el-form-item>
			</div>
			<div class="qxmidm mb20">
				<span class="qxmidmtxt">状态</span>
				<el-form-item prop="status">
					<el-radio-group v-model="tjForm.status" class="xzradio">
					    <el-radio :label="0">上架</el-radio>
					    <el-radio :label="1">下架</el-radio>
					  </el-radio-group>
				</el-form-item>
			</div>
			
			
			<div class="qxmidm pb32">
				<el-button type="primary" class="ssbtn" style="width: 5.375rem; margin-right: 80px; margin-left: 25px;" @click="submittjForm('tjForm')">确认</el-button>
				<el-button class="cancelbtn" style="width: 5.375rem;" @click="resettjForm('tjForm')">取消</el-button>
			</div>
		</el-form></div><!--ggbjmid end-->
	</el-dialog>
</div>
</template>

<script>
import axios from "axios"
	
export default {
    data(){
		return{
			tableData:[	],
			pageSize:20,
			currentPage:1,
			total:0,
			id:'',
			bjDialog:false,
			bjForm:{
				title:'',
				img:'',
				url:'',
				ad_type:'',
				ad_location:'',
				mid:'',
				sort:'',
				status:'',
				is_deleted:'',
				jump_type: ''
			},
			rules: {
			 title: [
			  {required: true, message: '请输入标题', trigger: 'blur'}
			 ],
			},
			upheaders:{
                //此处设置请求头
                // token:JSON.parse(localStorage.getItem('uInfo')).token
                // token:JSON.parse(this.$localStorage.getItem('userInfo')).token
            },
			movieOptions: [],
			bjvalnum:25,
			tjDialog:false,
			
			tjForm:{
				id:'',
				title:'',
				img:'',
				url:'',
				ad_type:'',
				ad_location:'',
				mid:'',
				sort:0,
				status:1,
				is_deleted:0,
				jump_type: ''
			},
			tjrules: {
			 title: [
			  {required: true, message: '请输入标题', trigger: 'blur'}
			 ],
			//  id: [
			//   {required: true, message: '请输入ID', trigger: 'blur'}
			//  ],
			// url: [
			//   {required: true, message: '请输入网址', trigger: 'blur'}
			//  ],
			},
			
		}
	},//data
	mounted(){
		this.getData()
		
		
	},//
	methods:{
		        SellerUploadImg(file){
		           
		            let form = new FormData()
		            form.append('file',file.file)
		           
					let token=this.$localStorage.getItem('userInfo').token
		            let config = {
		                headers: {
		                    "token":token
		                }
		            }
		
		
		            axios.post('/upload/UploadAd',form,config).then(res => {
		                console.log(res.data.data)
							this.bjForm.img=res.data.data
		               
		            }).catch(err => {
		                console.log(err)
		               
		                this.$message.error('图片上传失败')
		            })
		        },   
		SellerUploadImgtj(file){
		           
		            let form = new FormData()
		            form.append('file',file.file)
		           
					let token=this.$localStorage.getItem('userInfo').token
		            let config = {
		                headers: {
		                    "token":token
		                }
		            }
		
		
		            axios.post('/upload/UploadAd',form,config).then(res => {
		                console.log(res.data.data)
							this.tjForm.img=res.data.data
		               
		            }).catch(err => {
		                console.log(err)
		               
		                this.$message.error('图片上传失败')
		            })
		        },   
		
		handleAvatarSuccess(res, file) {
		        
		      },
		
		getData(){
			const that=this
			let data={
				 limit: that.pageSize, 
				 offset: that.currentPage-1
			}
			this.$api.getAd(data).then(res => {
			    that.total=res.data.data.count
				that.tableData=res.data.data.rows
				that.tableData.sort((a,b)=>{
					return b.sort - a.sort 
				})
			})
		},//getData
		getMovie(){
			const that=this
			let data={
				 limit: '', 
				 offset: ''
			}
			this.$api.getMovie(data).then(res => {
			    //that.total=res.data.data.count
				that.movieOptions=res.data.data
			})
		},//getData
		ckbj(index,row){
			this.bjDialog=true
			this.id=row.id
			this.bjForm.title=row.title
			this.bjForm.url=row.url
			this.bjForm.description=row.description
			this.bjForm.ad_type=row.ad_type
			this.bjForm.ad_location=row.ad_location
			this.bjForm.mid=row.mid
			this.bjForm.sort=row.sort
			this.bjForm.status=row.status
			this.bjForm.is_deleted=row.is_deleted
			this.bjForm.jump_type= row.jump_type
			// console.log('this.bjForm.jump_type',this.bjForm.jump_type, typeof this.bjForm.jump_type);

			if(row.ad_type==0){
				this.bjForm.img=row.wap_img
			}else{
				this.bjForm.img=row.pc_img
			}
			if(row.ad_type==0){
				this.bjForm.ad_location=row.ad_location
			}else{
				this.bjForm.ad_location=3
			}
		},//ckbj
		submitbjForm(formName){
					 this.$refs[formName].validate((valid) =>{
						 if (valid){
							const that=this; 
							let data={
								id:that.id,
								title:that.bjForm.title,
								description:that.bjForm.description,
								ad_type:that.bjForm.ad_type,
								img:that.bjForm.img,
								url:that.bjForm.url,
								ad_location:that.bjForm.ad_location,
								mid:that.bjForm.mid,
								sort:that.bjForm.sort,
								status:that.bjForm.status,
								is_deleted:that.bjForm.is_deleted,
								jump_type: that.bjForm.jump_type
							}
							this.$api.setAd(data).then(res =>{
								if(res.data.code==0){
									that.$message.success(res.data.msg);
									setTimeout(function(){
										that.bjDialog=false
										that.getData()
									},100)
								}
							})
						 }
					 })
		},//submitbjForm
		resetbjForm(formName) {
		  this.$refs[formName].resetFields();
			this.bjDialog=false
		},
		submittjForm(formName){
					 this.$refs[formName].validate((valid) =>{
						 if (valid){
							const that=this; 
							let data={
								//id:that.tjForm.id,
								title:that.tjForm.title,
								description:that.tjForm.description,
								ad_type:that.tjForm.ad_type,
								img:that.tjForm.img,
								url:that.tjForm.url,
								ad_location:that.tjForm.ad_location,
								mid:that.tjForm.mid,
								sort:that.tjForm.sort,
								status:that.tjForm.status,
								is_deleted:that.tjForm.is_deleted,
								jump_type: that.tjForm.jump_type
							}
							this.$api.setAd(data).then(res =>{
								if(res.data.code==0){
									that.$message.success(res.data.msg);
									setTimeout(function(){
										that.tjDialog=false
										that.getData()
									},100)
								}
							})
						 }
					 })
		},//submitbjForm
		resettjForm(formName) {
		  this.$refs[formName].resetFields();
			this.tjDialog=false
		},
		
		handleSizeChange(val) {
			this.pageSize=val
			this.getData()
		       
		
		      },
		  handleCurrentChange(val) {
			this.currentPage=val
			this.getData()
		
		  },
		  ckxj(index,row){
		  this.$confirm('确定下架吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				  }).then(() => {
					  const that=this
					  let imgAd
					  if(row.ad_type==0){
					  	imgAd=row.wap_img
					  }else{
					  	imgAd=row.pc_img
					  }
					  let data={
					  	 id:row.id,
					  	 title:row.title,
					  	 description:row.description,
					  	 ad_type:row.ad_type,
					  	 img:imgAd,
					  	 url:row.url,
					  	 ad_location:row.ad_location,
					  	 mid:row.mid,
					  	 sort:row.sort,
					  	 status:1,
					  	 is_deleted:row.is_deleted
					  }
					  this.$api.setAd(data).then(res => {
					  	if(res.data.code==0){
					  		that.$message.success('下架成功!');
					  		setTimeout(function(){
					  			that.getData()
					  		},100)
					  	}
					     
					  })
					
				  }).catch(() => {
					this.$message({
					  type: 'info',
					  message: '已取消下架'
					});          
				  });
		  },//ckxj
		  cksj(index,row){
		  this.$confirm('确定上架吗？', {
		  					confirmButtonText: '确定',
		  					cancelButtonText: '取消',
		  					type: 'warning'
		  				  }).then(() => {
		  					  const that=this
		  					  let imgAd
		  					  if(row.ad_type==0){
		  					  	imgAd=row.wap_img
		  					  }else{
		  					  	imgAd=row.pc_img
		  					  }
		  					  let data={
		  					  	 id:row.id,
		  					  	 title:row.title,
		  					  	 description:row.description,
		  					  	 ad_type:row.ad_type,
		  					  	 img:imgAd,
		  					  	 url:row.url,
		  					  	 ad_location:row.ad_location,
		  					  	 mid:row.mid,
		  					  	 sort:row.sort,
		  					  	 status:0,
		  					  	 is_deleted:row.is_deleted
		  					  }
		  					  this.$api.setAd(data).then(res => {
		  					  	if(res.data.code==0){
		  					  		that.$message.success('上架成功!');
		  					  		setTimeout(function(){
		  					  			that.getData()
		  					  		},100)
		  					  	}
		  					     
		  					  })
		  					
		  				  }).catch(() => {
		  					this.$message({
		  					  type: 'info',
		  					  message: '已取消上架'
		  					});          
		  				  });
		  },//cksj
		  ckdel(index,row){
		  this.$confirm('确定删除吗？', {
		  					confirmButtonText: '确定',
		  					cancelButtonText: '取消',
		  					type: 'warning'
		  				  }).then(() => {
		  					  const that=this
		  					  let imgAd
		  					  if(row.ad_type==0){
		  					  	imgAd=row.wap_img
		  					  }else{
		  					  	imgAd=row.pc_img
		  					  }
		  					  let data={
		  					  	 id:row.id,
		  					  	 title:row.title,
		  					  	 description:row.description,
		  					  	 ad_type:row.ad_type,
		  					  	 img:imgAd,
		  					  	 url:row.url,
		  					  	 ad_location:row.ad_location,
		  					  	 mid:row.mid,
		  					  	 sort:row.sort,
		  					  	 status:row.status,
		  					  	 is_deleted:1
		  					  }
		  					  this.$api.setAd(data).then(res => {
		  					  	if(res.data.code==0){
		  					  		that.$message.success('删除成功!');
		  					  		setTimeout(function(){
		  					  			that.getData()
		  					  		},100)
		  					  	}
		  					     
		  					  })
		  					
		  				  }).catch(() => {
		  					this.$message({
		  					  type: 'info',
		  					  message: '已取消上架'
		  					});          
		  				  });
		  },//ckdel
		 
		        
				
			
		     
	},//methods
	created:function(){
		this.getMovie()
	},//
}
</script>

<style lang="less" scoped>

</style>